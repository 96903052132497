/** @format */

import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { BsFillClockFill } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { BsTagsFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { Select, Table } from "antd";
import { Card } from "primereact/card";
import StarRatings from "react-star-ratings";
import {
	GetAllBookingCount,
	GetCompletedCount,
	GetDeletedCount,
	GetDriverCount,
	GetMostBookedService,
	GetPendingCount,
	GetServiceCount,
	GetTopRatedCourses,
	GetTotalRevenueGenerated,
	GetUserCount,
} from "../../services/Api/DashboardApi";
const { Option } = Select;

const Dashboard = () => {
	const [driverData, setDriverData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [mostBookedService, setMostBookedService] = useState([]);
	const [bookingCount, setBookingCount] = useState([]);
	const [pendingCount, setPendingCount] = useState([]);
	const [completedCount, setCompletedCount] = useState([]);
	const [deletedCount, setDeletedCount] = useState([]);
	const [revenuePeriod, setRevenuePeriod] = useState("weekly");
	const [totalRevenueData, setTotalRevenueData] = useState([]);
	const [topRatedCourse, setTopRatedCourse] = useState([]);

	ChartJS.register(ArcElement, Tooltip, Legend);
	const data = {
		labels: mostBookedService.map((item) => item.service_name || ""),

		datasets: [
			{
				data: mostBookedService.map((item) => item.booking_count || 0),

				backgroundColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderColor: ["#798BFF", "#B8ACFF", "#FFA9CE", "#F9DB7C"],
				borderWidth: 1,
			},
		],
	};

	mostBookedService.forEach((item) => {
		console.log("Service Booking Name:", item.service_id);
	});

	//get customer count
	const getMostBookedService = async () => {
		try {
			const result = await GetMostBookedService(
				localStorage.getItem("adminToken")
			);
			setMostBookedService(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getCustomerData = async () => {
		try {
			const result = await GetUserCount(localStorage.getItem("adminToken"));
			setCustomerData(result.data.data);
			console.log("customerdata====>", customerData);
		} catch (e) {
			console.log(e);
		}
	};

	//get customer count
	const getDriverData = async () => {
		try {
			const result = await GetDriverCount(localStorage.getItem("adminToken"));
			setDriverData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	//get likes count
	const getServiceCount = async () => {
		try {
			const result = await GetServiceCount(localStorage.getItem("adminToken"));
			setServiceData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getBookingCount = async () => {
		try {
			const result = await GetAllBookingCount(
				localStorage.getItem("adminToken")
			);
			setBookingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getPendingBookingCount = async () => {
		try {
			const result = await GetPendingCount(localStorage.getItem("adminToken"));
			setPendingCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getCompletedBookingCount = async () => {
		try {
			const result = await GetCompletedCount(
				localStorage.getItem("adminToken")
			);
			setCompletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getDeletedBookingCount = async () => {
		try {
			const result = await GetDeletedCount(localStorage.getItem("adminToken"));
			setDeletedCount(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const handleGetTotalRevenueGenerated = async (newPeriod) => {
		setRevenuePeriod(newPeriod);
		try {
			const result = await GetTotalRevenueGenerated(newPeriod); // Call the API
			setTotalRevenueData(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handleGetTotalRevenueGenerated(revenuePeriod); // Fetch data for the default period when the component loads
	}, []);

	const getTopRatedCourse = async () => {
		try {
			const result = await GetTopRatedCourses(
				localStorage.getItem("adminToken")
			);
			setTopRatedCourse(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getServiceCount();
		getDriverData();
		getCustomerData();
		getMostBookedService();
		getBookingCount();
		getDeletedBookingCount();
		getCompletedBookingCount();
		getPendingBookingCount();
		getTopRatedCourse();
	}, []);

	const renderStars = (rating) => {
		const safeRating = Number(rating) || 0;
		return (
			<StarRatings
				rating={safeRating}
				starRatedColor="#FAAE42"
				starEmptyColor="gray"
				starDimension="20px"
				starSpacing="2px"
				numberOfStars={5}
				name="rating"
			/>
		);
	};

	const columnsTopRatedCourse = [
		{
			title: "Service Name",
			dataIndex: "name",
			key: "name",
			width: "20%",
		},
		{
			title: "Average Rating",
			dataIndex: "average_rating",
			key: "average_rating",
			width: "20%",
			render: (rating) => renderStars(rating),
		},
	];

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					marginBottom: "20PX",
				}}
			>
				<h3 className="dashboard_title">Dashboard</h3>
				<p className="page-sub-title" style={{ color: "green" }}>
					Welcome to Web Admin Panel!
				</p>
			</div>

			<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{
						borderRight: "5px solid #1677FF",
						borderRadius: "8px",
						height: "130px",
					}}
				>
					<div>
						<p className="desc">Total Users</p>
						<h4 className="mb-0 sub-title">{customerData}</h4>
					</div>
				</div>
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{
						borderRight: "5px solid #1677FF",
						borderRadius: "8px",
						height: "130px",
					}}
				>
					<div>
						<p className="desc">Total Employees</p>
						<h4 className="mb-0 sub-title">{driverData}</h4>
					</div>
				</div>

				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3"
					style={{
						borderRight: "5px solid #1677FF",
						borderRadius: "8px",
						height: "130px",
					}}
				>
					<div>
						<p className="desc">Total Services</p>
						<h4 className="mb-0 sub-title">{serviceData}</h4>
					</div>
				</div>
			</div>

			<div className="d-flex justify-content-between align-items-center gap-3 mb-3">
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 p-3 rounded-3"
					style={{
						height: "120px",
						background: "linear-gradient(to right, #FFB3BA, #FFDFBA)",
					}}
				>
					<div>
						<p className="desc">Total Bookings:</p>
						<h4 className="mb-0 sub-title">{bookingCount}</h4>
					</div>
					<div className="d-flex flex-column align-items-end"></div>
				</div>
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"
					style={{
						height: "120px",
						background: "linear-gradient(to right, #FFE4B5, #FFD1BA)"
					}}
				>
					<div>
						<p className="desc">Completed Bookings:</p>
						<h4 className="mb-0 sub-title">{completedCount}</h4>
					</div>
					<div className="d-flex flex-column align-items-end">
						{/* <h6 className="red">
							<FaCheckCircle style={{ fontSize: "40px" }} />
						</h6> */}
					</div>
				</div>
				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"
					style={{
						height: "120px",
						background: "linear-gradient(to right, #FFE4B5, #FFD1BA)"
					}}
				>
					<div>
						<p className="desc">Pending Bookings:</p>
						<h4 className="mb-0 sub-title">{pendingCount}</h4>
					</div>
					<div className="d-flex flex-column align-items-end"></div>
				</div>

				<div
					className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3"
					style={{
						height: "120px",
						background: "linear-gradient(to right, #FFD1BA, #FFABAB)" 
					}}
				>
					<div>
						<p className="desc">Deleted Bookings:</p>
						<h4 className="mb-0 sub-title">{deletedCount}</h4>
					</div>
					<div className="d-flex flex-column align-items-end"></div>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				{/* chart */}
				<div style={{ width: "30%", marginRight: "20px" }}>
					<Card style={{ height: "100%" }}>
						<h3 className="mb-5 title" style={{ marginBottom: "20px" }}>
							Booking Overview
						</h3>
						<Doughnut data={data} />
					</Card>
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: "column",
						width: "70%",
					}}
				>
					<div
						style={{ width: "100%", marginRight: "20px", marginBottom: "20px" }}
					>
						<Card>
							<h4 className="headingTitle">Top Highest rated Services</h4>
							<Table
								columns={columnsTopRatedCourse}
								dataSource={topRatedCourse}
								rowKey="course_id"
								pagination={false}
							/>
						</Card>
					</div>
					<div style={{ width: "100%", marginRight: "20px" }}>
						<Card
							style={{
								background: "linear-gradient(to right, #D6E6FF, #E1C6FF)",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
							}}
						>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<div>
										<h5 className="mb-5 title">Total Revenue Generated</h5>
									</div>
									<div style={{ marginBottom: "20px" }}>
										<Select
											defaultValue="weekly"
											style={{ width: 200 }}
											onChange={handleGetTotalRevenueGenerated}
										>
											<Option value="weekly">Weekly</Option>
											<Option value="monthly">Monthly</Option>
											<Option value="quarterly">Quarterly</Option>
											<Option value="annually">Annually</Option>
										</Select>
									</div>
								</div>
							</div>

							{/* Graph Section */}
							<div>
								<h5 style={{ color: "white" }}>${totalRevenueData}</h5>
							</div>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
