/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Card } from "primereact/card";
import "./Bookings.css";
import { message, Spin, Radio, Form, Select, Table, Tag } from "antd";
import { Button } from "primereact/button";
import { GetDriverByAdmin } from "../../services/Api/Api";
import { GetBookingById, UpdateBooking } from "../../services/Api/BookingApi";

const EditBookings = () => {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [driverList, setDriverList] = useState([]);

	const [bookingData, setBookingData] = useState({
		booking_status: "",
		booking_id: "",
		service_provider_ids: [],
		team_leader_id: "",
	});
	const [defaultTeamLeader, setDefaultTeamLeader] = useState(null);
	const [loading, setLoading] = useState(true);
	const [bookingStatus, setBookingStatus] = useState(
		bookingData?.booking_status || ""
	);

	//get drivers name
	const getDriversList = async () => {
		setLoading(true); // Set loading to true before the API call
		let res = await GetDriverByAdmin(id);
		if (res?.status === 200) {
			const drivers = res?.data?.data;
			const teamLeader = drivers?.find((driver) => driver.is_team_leader);
			if (teamLeader) {
				setDefaultTeamLeader(teamLeader.user_profile?.name);
			}

			setDriverList(drivers);
		} else {
		}
		setLoading(false);
	};

	useEffect(() => {
		getDriversList();
	}, []);

	//get booking by id
	useLayoutEffect(() => {
		const fetchBookingData = async () => {
			try {
				const res = await GetBookingById(id);
				const booking = res.data.data;
				setBookingData(booking);
				form.setFieldsValue(booking);
			} catch (error) {
				console.error("Failed to fetch booking data:", error);
			}
		};

		fetchBookingData();
	}, [id]);

	const onFinish = (values) => {
		console.log("Success:", values);
	};

	const handleDriverChange = (values) => {
		// Find the selected drivers by name
		const selectedDrivers = driverList.filter((driver) =>
			values.includes(driver.user_profile?.name)
		);

		// Extract driver IDs from selected drivers
		const selectedDriverIds = selectedDrivers.map((driver) => driver.id);

		setBookingData({
			...bookingData,
			service_provider_ids: selectedDriverIds, // Update the state with selected driver IDs
		});
	};

	const handleTeamLeaderChange = (value) => {
		// Find the selected team leader by name
		const selectedTeamLeader = driverList.find(
			(driver) => driver.user_profile?.name === value
		);

		// Extract team leader ID from the selected team leader
		const selectedTeamLeaderId = selectedTeamLeader
			? selectedTeamLeader.id
			: "";

		setBookingData({
			...bookingData,
			team_leader_id: selectedTeamLeaderId, // Update the state with the selected team leader ID
		});
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append("booking_id", bookingData?.id ? bookingData?.id : "");
		formData.append(
			"service_provider_ids",
			bookingData?.service_provider_ids ? bookingData?.service_provider_ids : ""
		);
		formData.append("booking_status", bookingStatus);

		formData.append(
			"team_leader_id",
			bookingData?.team_leader_id ? bookingData?.team_leader_id : ""
		);

		UpdateBooking(formData)
			.then((res) => {
				if (res.status === 201) {
					message.success("Booking updated successfully!");
				}
				navigate("/bookings");
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};

	const navigateToBooking = () => {
		navigate("/bookings");
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">BOOKING MANAGEMENT</h3>
					<p style={{ color: "green" }} className="page-sub-title">
						Edit Details of User's Booking By Providing Employee or changing the
						status
					</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToBooking}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Bookings</span>
					</Button>
				</div>
			</Box>

			<Form form={form} onFinish={onFinish}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Card style={{ width: "30%", marginRight: "20px" }}>
						<div
							style={{
								margin: "0 0 7px 0px",
							}}
						>
							<h4
								style={{
									fontSize: "1.2rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "600",
									marginBottom: "20px",
									color: "#1677FF",
								}}
							>
								General Information :
							</h4>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Booking Id:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData?.booking_unique_id}
							</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								User Name:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData?.booking_user?.user_profile?.name}
							</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Service Name:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData?.service_booking?.name}
							</p>

							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Date:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData.date && bookingData?.date.split("T")[0]}
							</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Time:
							</h5>
							<p style={{ marginBottom: "20px" }}>{bookingData?.time}</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Addreess:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData?.booking_address?.full_address}
							</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Booking Status:
							</h5>
							<p style={{ marginBottom: "20px" }}>
								{bookingData?.booking_status}
							</p>
							<h5
								style={{
									fontSize: "0.9rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "700",
									marginTop: "14px",
									color: "darkgray",
								}}
							>
								Notes:
							</h5>
							<p style={{ marginBottom: "20px" }}>{bookingData?.notes}</p>
						</div>
					</Card>

					<Card style={{ width: "70%" }}>
						<h4
							style={{
								fontSize: "1.2rem",
								fontFamily: "Cerebri Sans,sans-serif",
								fontWeight: "600",
								marginBottom: "20px",
								color: "#1677FF",
							}}
						>
							Change Booking Status and assign employee and team Leader :
						</h4>
						{loading ? (
							<Spin
								style={{
									display: "flex",
									justifyContent: "center",
									height: "100vh",
								}}
							/>
						) : (
							<>
								<Form.Item
									label="Select Booking Status:"
									name="booking_status"
									labelCol={{ span: 24 }}
								>
									<Select
										style={{ width: "100%" }}
										placeholder="Select booking status"
										onChange={(value) => {
											setBookingStatus(value);
										}}
										defaultValue={bookingData?.booking_status}
										options={[
											{
												value: "PENDING",
												label: "PENDING",
												disabled: true,
											},
											{
												value: "ACCEPTED",
												label: "ACCEPTED",
											},
											{
												value: "REJECTED",
												label: "REJECTED",
											},
										]}
									/>
								</Form.Item>

								<Form.Item
									label="Select Employee:"
									name="service_provider_ids"
									labelCol={{ span: 24 }}
								>
									<Select
										style={{ width: "100%" }}
										placeholder="Select an employee"
										mode="multiple"
										onChange={handleDriverChange}
										defaultValue={bookingData.service_provider_ids?.map(
											(id) =>
												driverList?.find((driver) => driver.id === id)
													?.user_profile?.name
										)}
									>
										{driverList?.map((driver) => (
											<Select.Option
												key={driver.id}
												value={driver.user_profile?.name}
												disabled={driver.is_service_provider}
											>
												{driver.user_profile?.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item
									label="Select Team Leader:"
									name="team_leader_id"
									labelCol={{ span: 24 }}
									initialValue={defaultTeamLeader}
								>
									<Select
										style={{ width: "100%" }}
										placeholder="Select a team leader"
										onChange={handleTeamLeaderChange}
										value={defaultTeamLeader}
										disabled={loading}
									>
										{driverList?.map((driver) => (
											<Select.Option
												key={driver.id}
												value={driver.user_profile?.name}
												// disabled={driver.is_team_leader === false}
											>
												{driver.user_profile?.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item>
									<Button
										icon="pi pi-check"
										severity="info"
										onClick={handleSubmit}
										style={{
											marginTop: "100px",
											height: "45px",
											padding: "20px",
											borderRadius: "5px",
										}}
									>
										Submit{" "}
									</Button>
								</Form.Item>
							</>
						)}
					</Card>
				</div>
			</Form>
		</Box>
	);
};

export default EditBookings;
