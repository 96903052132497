/** @format */

import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { IoMdChatboxes } from "react-icons/io";
import { LuSettings } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { FiActivity } from "react-icons/fi";
import { CiLogin } from "react-icons/ci";
import { Layout, theme, Menu, Spin, Badge } from "antd";
import { IoLayers } from "react-icons/io5";
import { LuLayers } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { MdManageAccounts } from "react-icons/md";
import { message } from "antd";
import Login from "../pages/Login/Login";
import { formatDistanceToNow } from "date-fns";
import { GiRingingBell } from "react-icons/gi";
import { ImNotification } from "react-icons/im";
import { Drawer, Button } from "antd";

import {
	GetAdminProfile,
	AdminLogin,
	GetNotifications,
	MarkAllNotificationAsRead,
} from "../services/Api/Api";
import { TbBrandBooking, TbBrandCashapp } from "react-icons/tb";
import "./MainLayout.scss";
import { FaRegBell } from "react-icons/fa6";
import { GetBookingRequestCount } from "../services/Api/BookingApi";
import { PiSealCheckFill } from "react-icons/pi";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [idData, setIdData] = useState({});
	const [loading, setLoading] = useState(false);
	const [hasBookings, setHasBookings] = useState(false);
	const [notificationData, setNotification] = useState([]);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [drawerVisible, setDrawerVisible] = useState(false);

	// Function to show drawer
	const showDrawer = () => {
		setDrawerVisible(true);
	};

	// Function to close drawer
	const onClose = () => {
		setDrawerVisible(false);
	};

	const handleMarkAllAsRead = () => {
		const formData = new FormData();
		formData.append(
			"notification_id",
			notificationData?.id ? notificationData?.id : ""
		);

		MarkAllNotificationAsRead(formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("All notifications marked as read successfully!");
					// Refresh the notifications after marking as read
					getNotifications();
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};

	const getNotifications = async () => {
		try {
			const result = await GetNotifications(localStorage.getItem("adminToken"));
			const notificationsArray = result?.data?.data || [];
			setNotification(notificationsArray); // Update the notification state
			console.log("notificationsArray", notificationsArray);
		} catch (error) {
			console.error("Error fetching notifications:", error);
			if (error.response && error.response.status === 401) {
				message.error("Token expired!");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 3000);
			}
		}
	};
	useEffect(() => {
		// Fetch notifications initially
		getNotifications();

		// Set up interval to fetch notifications every 10 seconds
		const intervalId = setInterval(getNotifications, 10000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	const getData = async () => {
		try {
			let result = await GetAdminProfile(localStorage.getItem("adminToken"));
			setIdData(result.data.data);
			if (result.status === 500 && result.message === "jwt expired") {
				navigate("/Login");
				message.error("Session Expired");
			} else {
				setIdData(result.data.data);
			}

			// Check booking requests count
			let bookingResult = await GetBookingRequestCount(
				localStorage.getItem("adminToken")
			);
			setHasBookings(bookingResult.data.data.hasBookings); // Update hasBookings state
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem("adminToken");
		message.success("Logged Out Successfully");
		setTimeout(() => {
			navigate("/Login");
		}, 1000);
	}

	const handleChatClick = async () => {
		setLoading(true);
		let formData = {
			email: "internationalhandymenSupport@gmail.com",
			password: "Support@123",
		};
		try {
			let res = await AdminLogin(formData);
			message.success("Successfully Logged In");
			setTimeout(() => {
				const token = res?.data?.data?.token;
				localStorage.removeItem("chatToken");
				localStorage.setItem("chatToken", token);
				navigate("/chats");
				setLoading(false);
			}, 1000);
		} catch (error) {
			if (error.response.data.message === "Error: User not found.") {
				message.error("Email Doesn't Exist");
			} else if (
				error.response.data.message ===
				"Error: Invalid email or password. Please try again."
			) {
				message.error("Invalid Password");
			} else if (
				error.response.data.message ===
				"Please Enter Required Fields : [ email_id || password ]"
			) {
				message.error("Please enter Required Fields");
			}
			setLoading(false);
		}
	};

	const token = localStorage.getItem("adminToken");
	const isAuthorize = JSON.parse(localStorage.getItem("authorize"));
	console.log(isAuthorize, typeof isAuthorize, "isAuthorize");

	const handleMailClick = () => {
		window.location.href =
			"https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox";
	};

	return (
		<>
			{token && isAuthorize ? (
				<>
					{loading ? (
						<div className="spinner-container">
							<Spin size="large" />
						</div>
					) : (
						<Layout>
							{!loading && (
								<Sider
									trigger={null}
									collapsible
									collapsed={collapsed}
									className="sidebar"
								>
									<div className="logo">
										<h2 className="text-white text-center py-3 mb-0 custom-logo-size">
											<span className="sm-logo"></span>
											<span className="lg-logo">HANDYMAN</span>
										</h2>
									</div>

									<Menu
										theme="dark"
										mode="inline"
										defaultSelectedKeys={[""]}
										onClick={({ key }) => {
											if (key === "signout") {
												logout();
											} else if (key === "chat") {
												handleChatClick();
											} else {
												navigate(key);
											}
										}}
										items={[
											{
												key: "/",
												icon: <AiOutlineDashboard className="fs-4" />,
												label: "Dashboard",
											},
											{
												key: "/users",
												icon: <FaUsers className="fs-4" />,
												label: "Clients",
											},
											{
												key: "/employees",
												icon: <FaUsers className="fs-4" />,
												label: "Employees",
											},
											{
												key: "/services",
												icon: <BiSolidCategoryAlt className="fs-4" />,
												label: "Services",
											},
											{
												key: "/bookings",
												icon: <TbBrandBooking className="fs-4" />,
												label: (
													<span>Bookings {hasBookings && <Badge dot />}</span>
												),
											},
											{
												key: "payment",
												icon: <TbBrandCashapp className="fs-4" />,
												label: "Payment",
											},
											{
												key: "chat",
												icon: <IoMdChatboxes className="fs-4" />,
												label: "Chat",
											},
											{
												key: "/t&c1",
												icon: <IoLayers className="fs-4" />,
												label: "Content",
												children: [
													{
														key: "/termsAndConditions",
														icon: <LuLayers className="fs-4" />,
														label: "T&C",
													},
													{
														key: "aboutUs",
														icon: <LuLayers className="fs-4" />,
														label: "About Us",
													},
												],
											},
											{
												key: "/adminList",
												icon: <MdManageAccounts className="fs-4" />,
												label: "Access Management",
											},
										]}
									/>
								</Sider>
							)}
							<Layout className="site-layout">
								<Header
									className="d-flex justify-content-between ps-1 pe-5"
									style={{
										padding: 0,
										background: colorBgContainer,
									}}
								>
									{React.createElement(
										collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
										{
											className: "trigger",
											onClick: () => setCollapsed(!collapsed),
										}
									)}
									<div className="d-flex gap-4 align-items-center">

									<div className="position-relative clicked">
									<FiMail className="fs-3" onClick={handleMailClick} />
								</div>
										<div
											role="button"
											id="dropdownMenuLink"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											className="d-flex align-items-center"
										>
											<div className="position-relative clicked">
												<FaRegBell
													className="fs-3"
													onClick={(event) => handleClick(event)}
												/>

												<span className="badge bg-warning rounded-circle p-1 position-absolute">
													{notificationData.activeNotificationCount}
												</span>
											</div>
										</div>

										<div
											className="dropdown-menu notification"
											aria-labelledby="dropdownMenuLink"
											style={{
												borderTopColor: "#6676FF",
												borderTopWidth: "4px",
												width: "350px",
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													height: "45px",
													alignItems: "center",
													padding: "10px",
												}}
											>
												<div>Notifications</div>
												<div
													style={{ cursor: "pointer", color: "#6676FF" }}
													onClick={handleMarkAllAsRead}
												>
													Mark All as Read
												</div>
											</div>

											<hr style={{ margin: "5px 0" }} />

											<div>
												{notificationData &&
												notificationData.notifications &&
												notificationData.notifications.length > 0 ? (
													notificationData.notifications
														.slice(0, 5)
														.map((item, index) => {
															const timeAgo = formatDistanceToNow(
																new Date(item.created_at),
																{ addSuffix: true }
															);

															return (
																<div key={index}>
																	{/* Render your notification item here */}
																	<div
																		className="notification_display"
																		style={{
																			display: "flex",
																			flexDirection: "column",
																			justifyContent: "space-between",
																			padding: "0px 10px",
																		}}
																	>
																		{item.notification ===
																		"Your booking has been started successfully" ? (
																			<React.Fragment>
																				<div
																					style={{
																						display: "flex",
																						flexDirection: "row",
																						justifyContent: "space-between",
																					}}
																				>
																					<div style={{ width: "10%" }}>
																						<GiRingingBell
																							style={{
																								fontSize: "40px",
																								color: "orange",
																							}}
																						/>
																					</div>
																					<div style={{ marginTop: "10px" }}>
																						<p>
																							Service has been started for{" "}
																							{
																								item.notification_user
																									.user_profile.name
																							}
																						</p>
																						<h5 className="time">{timeAgo}</h5>
																					</div>
																				</div>
																			</React.Fragment>
																		) : item.notification ===
																		  "Your booking has been Completed successfully" ? (
																			<React.Fragment>
																				<div
																					style={{
																						display: "flex",
																						flexDirection: "row",
																					}}
																				>
																					<div
																						style={{
																							width: "10%",
																							marginRight: "30px",
																						}}
																					>
																						<PiSealCheckFill
																							style={{
																								fontSize: "40px",
																								color: "green",
																							}}
																						/>
																					</div>
																					<div style={{ marginTop: "10px" }}>
																						<p>
																							Service has been completed for{" "}
																							{
																								item.notification_user
																									.user_profile.name
																							}
																						</p>
																						<h5 className="time">{timeAgo}</h5>
																					</div>
																				</div>
																			</React.Fragment>
																		) : (
																			<React.Fragment>
																				{item.notification_user &&
																					item.notification_user
																						.user_profile && (
																						<div
																							style={{
																								display: "flex",
																								flexDirection: "row",
																							}}
																						>
																							<div
																								style={{
																									width: "10%",
																									marginRight: "30px",
																								}}
																							>
																								<ImNotification
																									style={{
																										fontSize: "40px",
																										color: "red",
																									}}
																								/>
																							</div>
																							<div
																								style={{ marginTop: "10px" }}
																							>
																								<p>
																									{
																										item.notification_user
																											.user_profile.name
																									}{" "}
																									has requested a service
																								</p>
																								<h5 className="time">
																									{timeAgo}
																								</h5>
																							</div>
																						</div>
																					)}
																			</React.Fragment>
																		)}
																	</div>

																	{index < 4 && <hr />}
																</div>
															);
														})
												) : (
													// Updated UI for no notifications found
													<div
														style={{
															textAlign: "center",
															padding: "20px 0",
															color: "#6676FF",
														}}
													>
														<ImNotification
															style={{
																fontSize: "50px",
																color: "#6676FF",
															}}
														/>
														<p style={{ marginTop: "10px", fontSize: "18px" }}>
															No active notifications found
														</p>
													</div>
												)}
											</div>

											<hr style={{ margin: "5px 0" }} />

											<div
												onClick={showDrawer}
												style={{
													height: "40px",
													textAlign: "center",
													marginBottom: "13px",
													cursor: "pointer",
													color: "#6676FF",
												}}
											>
												View All
											</div>
										</div>

										{/* Ant Design Drawer for View All Notifications */}
										<Drawer
											title="All Notifications"
											placement="right"
											onClose={onClose}
											visible={drawerVisible}
											width={400}
										>
											<div>
												{notificationData &&
												notificationData.notifications &&
												notificationData.notifications.length > 0 ? (
													notificationData.notifications.map((item, index) => {
														const timeAgo = formatDistanceToNow(
															new Date(item.created_at),
															{ addSuffix: true }
														);
														return (
															<div key={index}>
																<div
																	className="notification_display"
																	style={{
																		display: "flex",
																		flexDirection: "column",
																		justifyContent: "space-between",
																		padding: "0px 10px",
																	}}
																>
																	{item.notification ===
																	"Your booking has been started successfully" ? (
																		<div>
																			<p>
																				Service started for{" "}
																				{
																					item.notification_user.user_profile
																						.name
																				}
																			</p>
																			<h5 className="time">{timeAgo}</h5>
																		</div>
																	) : item.notification ===
																	  "Your booking has been Completed successfully" ? (
																		<div>
																			<p>
																				Service completed for{" "}
																				{
																					item.notification_user.user_profile
																						.name
																				}
																			</p>
																			<h5 className="time">{timeAgo}</h5>
																		</div>
																	) : (
																		<div>
																			<p>
																				{
																					item.notification_user.user_profile
																						.name
																				}{" "}
																				has requested a service
																			</p>
																			<h5 className="time">{timeAgo}</h5>
																		</div>
																	)}
																</div>
																<hr />
															</div>
														);
													})
												) : (
													<p>No active notifications found</p>
												)}
											</div>
										</Drawer>

										<div className="vertical-line"></div>
										<div className="position-relative">
											<div className="d-flex align-items-center">
												<div
													role="button"
													id="dropdownMenuLink"
													data-bs-toggle="dropdown"
													aria-expanded="false"
													className="d-flex align-items-center"
												>
													<FaRegCircleUser className="fs-3 me-2" />
													<div className="d-flex flex-column">
														<p className="mb-0" style={{ fontWeight: "700" }}>
															{idData?.name}
														</p>
														<p className="mb-0">
															{idData?.admin_role?.name || ""}
														</p>
													</div>
												</div>
												<div
													className="dropdown-menu admin"
													aria-labelledby="dropdownMenuLink"
													style={{
														borderTopColor: "purple",
														borderTopWidth: "4px",
													}}
												>
													<li to="/viewAdmin">
														<Link
															className="dropdown-item py-1 mb-1"
															style={{ height: "auto", lineHeight: "30px" }}
															to="/viewAdmin"
														>
															<FiUser style={{ marginRight: "10px" }} />
															View Profile
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item py-1 mb-1"
															style={{ height: "auto", lineHeight: "30px" }}
															to="/settings"
														>
															<LuSettings style={{ marginRight: "10px" }} />
															Account Settings
														</Link>
													</li>
													<li>
														<Link
															className="dropdown-item py-1 mb-1"
															style={{ height: "auto", lineHeight: "30px" }}
															to="/loginLogs"
														>
															<FiActivity style={{ marginRight: "10px" }} />
															User Login Activity
														</Link>
													</li>
													<div className="dropdown-divider"></div>
													<li>
														<Link
															className="dropdown-item py-1 mb-1"
															style={{ height: "auto", lineHeight: "30px" }}
															onClick={() => logout()}
														>
															<CiLogin style={{ marginRight: "10px" }} />
															Sign Out
														</Link>
													</li>
												</div>
											</div>
										</div>
									</div>
								</Header>
								<Content
									style={{
										margin: "24px 16px",
										padding: 24,
										minHeight: 280,
										background: colorBgContainer,
										position: "relative",
									}}
								>
									<>
										<ToastContainer
											position="top-right"
											autoClose={250}
											hideProgressBar={false}
											newestOnTop={true}
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable
											theme="light"
										/>
										<Outlet />
									</>
								</Content>
							</Layout>
						</Layout>
					)}
				</>
			) : (
				<Login />
			)}
		</>
	);
};

export default MainLayout;
