/** @format */

import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import "./Chat.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CgAttachment } from "react-icons/cg";
import { SendMessage, SendNotification } from "../../services/Api/ChatApi";

const firebaseConfig = {
	apiKey: "AIzaSyAfauvQ26Gwt3oMojtDIapHeUogWVfAFH0",
	authDomain: "international-handymen.firebaseapp.com",
	databaseURL: "https://international-handymen-default-rtdb.firebaseio.com",
	projectId: "international-handymen",
	storageBucket: "international-handymen.appspot.com",
	messagingSenderId: "780778178529",
	appId: "1:780778178529:web:f235e4f497b913224c74a0",
	measurementId: "G-C59K2K2CJW",
};
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

function ChatMessage({ message, imageUrls, source, timestamp }) {
	const messageClass =
		source === "SENDER" ? "sender-message" : "receiver-message";
	const alignClass = source === "SENDER" ? "align-left" : "align-right";
	const timeString = new Date(timestamp).toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
	});

	const handleImageClick = (imageUrl) => {
		// Open the image in a new window or modal
		window.open(imageUrl, "_blank");
	};
	return (
		<>
			{message && (
				<>
					<div className={`message ${messageClass} ${alignClass}`}>
						<div>{message === "" ? null : message}</div>
						<div className={`message-time ${alignClass}`}>{timeString}</div>
					</div>
				</>
			)}
			{imageUrls &&
				imageUrls?.map((url, index) => {
					return (
						<div
							key={index}
							className={`message ${messageClass} ${alignClass}`}
							style={{ cursor: "pointer" }}
							onClick={() => handleImageClick(url)}
						>
							<div>
								<img
									key={index}
									src={`${url}`}
									alt={`Chat Attachment ${index + 1}`}
									width="200px"
									height="150px"
								/>
							</div>
							<div className={`message-time ${alignClass}`}>{timeString}</div>
						</div>
					);
				})}
		</>
	);
}

const Support = ({ id, chatType }) => {
	const [message, setMessage] = useState("");
	const [chatMessages, setChatMessages] = useState([]);
	const [previewImages, setPreviewImages] = useState([]);
	const [imageFiles, setImageFiles] = useState([]);
	const [imageExists, setImageExists] = useState(false);
	console.log("previewImages", previewImages);
	useEffect(() => {
		const databaseUrl =
			chatType === "user"
				? `USER_ADMIN_CHAT/messages/ADMIN_2/USER_${id}`
				: `EMPLOYEE_ADMIN_CHAT/messages/ADMIN_2/USER_${id}`;

		const messagesRef = ref(db, databaseUrl);
		onValue(messagesRef, (snapshot) => {
			const messages = [];
			snapshot.forEach((childSnapshot) => {
				messages.push(childSnapshot.val());
			});
			setChatMessages(messages);
			// Update chat messages state
		});
	}, [db, id]);

	const currentTime = new Date();
	const timestampInEpoch = currentTime.getTime();

	const sendMessage = async () => {
		const senderUrl =
			chatType === "user"
				? `USER_ADMIN_CHAT/messages/ADMIN_2/USER_${id}`
				: `EMPLOYEE_ADMIN_CHAT/messages/ADMIN_2/USER_${id}`;
		const receiverUrl =
			chatType === "user"
				? `USER_ADMIN_CHAT/messages/USER_${id}/ADMIN_2`
				: `EMPLOYEE_ADMIN_CHAT/messages/USER_${id}/ADMIN_2`;
		try {
			const senderMessageRef = push(ref(db, senderUrl));
			const receiverMessageRef = push(ref(db, receiverUrl));

			await Promise.all([
				set(senderMessageRef, {
					message,
					timestamp: timestampInEpoch,
					source: "SENDER",
					read: true,
				}),
				set(receiverMessageRef, {
					message,
					timestamp: timestampInEpoch,
					source: "RECEIVER",
					read: false, // Set read as false for receiver's message
				}),
			]);

			setMessage("");

			setPreviewImages([]);
			setImageExists(false);

			const notificationData = {
				message,
				receiverUserId: id,
			};
			await SendNotification(notificationData);
		} catch (e) {
			console.error("Error sending message: ", e);
		}
	};

	const chatWindowRef = React.useRef(null);
	const scrollToBottom = () => {
		if (chatWindowRef.current) {
			chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [chatMessages]);

	const groupMessagesByDate = (messages) => {
		const groupedMessages = [];
		let currentDate = null;

		messages.forEach((msg) => {
			const timestamp = msg.timestamp;
			const msgDate = new Date(timestamp);

			// Check if the date is valid
			if (!isNaN(msgDate.getTime())) {
				const formattedDate = msgDate.toLocaleDateString("en-US", {
					day: "2-digit",
					month: "numeric",
					year: "numeric",
				});

				if (formattedDate !== currentDate) {
					currentDate = formattedDate;
					groupedMessages.push({
						date: currentDate,
						messages: [msg],
					});
				} else {
					const lastIndex = groupedMessages.length - 1;
					groupedMessages[lastIndex].messages.push(msg);
				}
			} else {
				console.error("Invalid date encountered:", msgDate, "in message:", msg);
			}
		});

		return groupedMessages;
	};

	const formattedMessages = groupMessagesByDate(chatMessages);

	const handleImageChange = async (e) => {
		try {
			const files = e.target.files;
			// Read and display image previews
			const imagePreviews = [];
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const reader = new FileReader();
				reader.onload = (event) => {
					imagePreviews.push(event.target.result);
					if (imagePreviews.length === files.length) {
						setPreviewImages(imagePreviews);
						setImageExists(true); // Update imageExists state when image previews are set
					}
				};
				reader.readAsDataURL(file);
			}
			setImageFiles(files);

			console.log("imagePreviews", imagePreviews);
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	const handleImageSubmit = async (e) => {
		try {
			e.preventDefault();
			const formData = new FormData();
			if (imageFiles.length > 0) {
				formData.append("receiverUserId", id);
				formData.append("message", "");

				// Fetch each image and append its Blob to the FormData
				for (const image of imageFiles) {
					// const blob = await fetch(image).then((res) => res.blob()); // Convert image to Blob
					formData.append(`images`, image, image.name); // Append Blob to form data
				}

				console.log("formdata", formData);

				const res = await SendMessage(formData);

				setMessage("");
				setPreviewImages([]);
				setImageExists(false);
			} else {
				console.error("No images to send.");
			}
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	const handleRemoveImage = (indexToRemove) => {
		return () => {
			const updatedPreviews = previewImages.filter(
				(_, index) => index !== indexToRemove
			);
			setPreviewImages(updatedPreviews);

			// Clear the file input value if no more images are present
			if (updatedPreviews.length === 0) {
				const fileInput = document.getElementById("imageInput");
				if (fileInput) {
					fileInput.value = null;
				}
				setImageExists(false);
			}
		};
	};

	return (
		<div>
			<div className="messageContainer" ref={chatWindowRef}>
				{formattedMessages.map((group) => (
					<React.Fragment key={group.date}>
						<div className="date-header">
							{new Date(group.date).toLocaleDateString("en-US", {
								day: "2-digit",
								month: "numeric",
								year: "numeric",
							})}
						</div>

						{group.messages.map((msg, index) => (
							<ChatMessage
								key={index}
								message={msg.message}
								source={msg.source}
								timestamp={msg.timestamp}
								imageUrls={msg.imageUrls}
							/>
						))}
					</React.Fragment>
				))}
			</div>
			<Box
				sx={{
					padding: "4px 20px",
				}}
			>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={10} className="main_chat_grid">
						<TextField
							sx={{
								"& fieldset": { border: "none" },
							}}
							size="small"
							fullWidth
							placeholder={imageExists ? "" : "Type a message"}
							variant="outlined"
							value={imageExists ? "" : message}
							onChange={(e) => setMessage(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									console.log(message.trim(), "fefeffrfrff");
									if (message.trim() === "") {
										handleImageSubmit(e);
									} else {
										sendMessage();
									}
								}
							}}
						/>
						{imageExists ? (
							<div style={{ position: "relative", display: "inline-block" }}>
								{previewImages.map((preview, index) => (
									<div
										key={index}
										style={{ position: "relative", display: "inline-block" }}
									>
										<img
											src={preview}
											alt={`Preview ${index}`}
											style={{
												height: "100px",
												width: "100px",
												margin: "10px",
												cursor: "pointer",
											}}
										/>
										<CloseIcon
											fontSize="small"
											color="warning"
											style={{
												position: "absolute",
												top: 0,
												right: 0,
												cursor: "pointer",
											}}
											onClick={handleRemoveImage(index)}
										/>
									</div>
								))}
							</div>
						) : (
							<>
								<label htmlFor="image-upload" className="image_upload">
									<CgAttachment
										color="primary"
										style={{
											cursor: "pointer",
											fontSize: "larger",
											margin: "10px",
										}}
									/>
								</label>
								<input
									type="file"
									id="image-upload"
									accept="image/*"
									onChange={handleImageChange}
									style={{ display: "none" }}
									multiple
								/>
							</>
						)}
					</Grid>

					<Grid item xs={2}>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							endIcon={<SendIcon />}
							onClick={(e) => {
								if (message.trim() === "") {
									handleImageSubmit(e);
								} else {
									sendMessage();
								}
							}}
							// onKeyPress={(e) => {
							// 	if (e.key === "Enter") {
							// 		console.log("MESSJHDJHEWFJW",message,e)

							// 		if (message.trim() === "") {
							// 			handleImageSubmit(e);
							// 		} else {
							// 			sendMessage();
							// 		}
							// 	}
							// }}
							disabled={
								message.trim() === "" &&
								previewImages.length === 0 &&
								!imageExists
							}
						>
							Send
						</Button>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};

export default Support;
