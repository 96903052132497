import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ChangePassword from "./pages/Sidebar/Settings/ChangePassword/ChangePassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import AdminList from "./pages/AdminList";
import RoleList from "./pages/Roles/RoleList";
import AddRole from "./pages/Roles/AddRole";
import EditRole from "./pages/Roles/EditRole";
import AddCustomer from "./pages/Customer/AddCustomer";
import ViewCustomer from "./pages/Customer/ViewCustomer";
import AdminUser from "./pages/AdminManagement/AdminUser";
import ViewAdmin from "./pages/AdminManagement/ViewAdmin";
import AddAdmin from "./pages/AdminManagement/AddAdmin";
import EditAdmin from "./pages/AdminManagement/EditAdmin";
import LoginLogs from "./pages/Sidebar/LoginActivity/LoginLogs";
import Settings from "./pages/Sidebar/Settings/Settings";
import Service from "./pages/Services/Service";
import AddService from "./pages/Services/AddService";
import EditService from "./pages/Services/EditService";
import ViewService from "./pages/Services/ViewService";
import Bookings from "./pages/Bookings/Bookings";
import TermsAndConditions from "./pages/Content/TermsConditions/TermsAndConditions";
import AddTermsAndConditions from "./pages/Content/TermsConditions/AddTermsAndConditions";
import EditTermsAndConditions from "./pages/Content/TermsConditions/EditTermsAndConditions";
import Support from "./pages/Content/Support/Support";
import EditSupport from "./pages/Content/Support/EditSupport";
import AddSupport from "./pages/Content/Support/AddSupport";
import AboutUs from "./pages/Content/AboutUs/AboutUs";
import AddAboutUs from "./pages/Content/AboutUs/AddAboutUs";
import EditAboutUs from "./pages/Content/AboutUs/EditAboutUs";
import EditBookings from "./pages/Bookings/EditBookings";
import ViewBooking from "./pages/Bookings/ViewBooking";
import Test from "./pages/test";
import Error401 from "./pages/ErrorPages/Error401";
import User from "./pages/Customer/User";
import ServiceProvider from "./pages/ServiceProvider/ServiceProvider";
import AddServiceProvider from "./pages/ServiceProvider/AddServiceProvider";
import ViewServiceProvider from "./pages/ServiceProvider/ViewServiceProvider";
import BannerContent from "./pages/Content/BannerContent/BannerContent";
import AddBanner from "./pages/Content/BannerContent/AddBanner";
import EditBanner from "./pages/Content/BannerContent/EditBanner";
import AuthorizationSuccess  from "./pages/Login/AuthorizationSuccess";
import AuthrizationFailure from "./pages/Login/AuthrizationFailure";
import ChatUI from "./pages/Chat/ChatUI";
import Chat from "./pages/Chat/Chat";
import Ratings from "./pages/Services/Rating";
import SyncCalendar from "./pages/Sidebar/Settings/SyncCalendar/SyncCalendar";
import Payment from "./pages/Payment/Payment";
function App() {
	return (
		<Router>
			<Routes>
				<Route path="/forgot-password" element={<Forgotpassword />} />
				<Route path="/Login" element={<Login />} />
				<Route path="/error401" element={<Error401 />} />
				<Route path="/authorization-success/:token" element={<AuthorizationSuccess />} />
				<Route path="/authorization-failure" element={<AuthrizationFailure />} />
				<Route path="/chats" element={<ChatUI />} />
				<Route path="/chat" element={<Chat />} />
				<Route path="/" element={<MainLayout />}>
					<Route index element={<Dashboard />} />
					<Route path="/reset-password" element={<ChangePassword />} />
					<Route path="/employees" element={<ServiceProvider />} />
					<Route path="/addEmployee" element={<AddServiceProvider />} />
					<Route path="/viewEmployee/:id" element={<ViewServiceProvider />} />
					<Route path="/addUser" element={<AddCustomer />} />
					<Route path="/viewUser/:id" element={<ViewCustomer />} />
					<Route path="/test" element={<Test />} />
					<Route path="adminUser" element={<AdminList />} />
					<Route path="/role-list" element={<RoleList />} />
					<Route path="/addRole" element={<AddRole />} />
					<Route path="/editRole/:id" element={<EditRole />} />
					<Route path="/adminList" element={<AdminUser />} />
					<Route path="/viewAdmin" element={<ViewAdmin />} />
					<Route path="/addAdmin" element={<AddAdmin />} />
					<Route path="/editAdmin/:id" element={<EditAdmin />} />
					<Route path="/loginLogs" element={<LoginLogs />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/services" element={<Service />} />
					<Route path="/addService" element={<AddService />} />
					<Route path="/serviceRatings/:id" element={<Ratings />} />
					<Route path="/editService/:id" element={<EditService />} />
					<Route path="/viewService/:id" element={<ViewService />} />
					<Route path="/bookings" element={<Bookings />} />
					<Route path="/editBooking/:id" element={<EditBookings />} />
					<Route path="/viewBooking/:id" element={<ViewBooking />} />

					<Route path="/termsAndConditions" element={<TermsAndConditions />} />
					<Route
						path="/addTermsAndConditions"
						element={<AddTermsAndConditions />}
					/>
					<Route
						path="/editTermsAndConditions"
						element={<EditTermsAndConditions />}
					/>
					<Route path="/support" element={<Support />} />
					<Route path="/editSupport/:id" element={<EditSupport />} />
					<Route path="/addSupport" element={<AddSupport />} />
					<Route path="/aboutUs" element={<AboutUs />} />
					<Route path="/addAboutUs" element={<AddAboutUs />} />
					<Route path="/syncCalendar" element={<SyncCalendar />} />
					<Route path="/editAboutUs" element={<EditAboutUs />} />
					<Route path="/users" element={<User />} />
					<Route path="/banner" element={<BannerContent />} />
					<Route path="/addBanner" element={<AddBanner />} />
					<Route path="/editBanner/:id" element={<EditBanner />} />
					<Route path="/payment" element={<Payment />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
