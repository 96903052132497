import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAdmins, DeleteAdmin } from "../../services/Api/Api";
import { Button } from "primereact/button";
import Alert from "../Customer/Alert";
import { InputText } from "primereact/inputtext";

const AdminUser = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  // Fetch all admins
  const getData = async () => {
    try {
      setLoading(true);
      const result = await GetAdmins(localStorage.getItem("adminToken"));
      const adminsData = result.data.data.map((item, index) => ({
        ...item,
        index: index + 1, // Add index for S.No
      }));
      setData(adminsData);
      setFilteredData(adminsData); // Set both data and filteredData
      console.log("admins", adminsData);
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 401) {
        navigate("/error401");
        console.log("You do not have access to this page as a sub-admin.");
      } else {
        console.log("Error loading data. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigateToAddAdmin = () => {
    navigate("/addAdmin");
  };

  const navigateToEditAdmin = (id) => {
    navigate(`/editAdmin/${id}`);
  };

  const removeUser = (user_id) => {
    DeleteAdmin(user_id, localStorage.getItem("adminToken"))
      .then((res) => {
        message.success(res?.data?.message);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSearch = (searchField) => {
    const filteredList = data.filter((admin) =>
      admin.name.toLowerCase().includes(searchField.toLowerCase())
    );
    setFilteredData(filteredList);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index - b.index,
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "30%",
    },
    {
      title: "Role",
      dataIndex: ["admin_role", "name"], // Access nested role name
      key: "role",
      width: "20%",
    },
    {
      title: "Actions",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <div>
          <Button
            icon="pi pi-pencil"
            rounded
            outlined
            className="mr-2"
            style={{ marginRight: "10px", borderRadius: "25px" }}
            onClick={() => navigateToEditAdmin(record.id)}
          />
          <Alert
            title="Admin"
            handleDelete={() => removeUser(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <Box>
      {loading ? (
        <p style={{ fontSize: "16px" }}>Loading...</p>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div>
              <h3 className="page-title">Admin List</h3>
              <p className="page-sub-title">
                View, Edit, Delete, and Create Admin
              </p>
            </div>
            <Box display="flex" alignItems="center">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  onChange={(e) => onSearch(e.target.value)}
                  placeholder="Search by name..."
                />
              </span>
              <Button
                label="Add New Admin"
                icon="pi pi-plus"
                severity="info"
                style={{ margin: "0px 10px", borderRadius: "5px" }}
                onClick={navigateToAddAdmin}
              />
            </Box>
          </Box>
          <div style={{ marginTop: "20px" }}>
            <Table
              columns={columns}
              dataSource={filteredData} // Use filtered data for rendering
              rowKey={(record) => record.id}
              pagination={{
                pageSize,
                onChange: (page, pageSize) => setPageSize(pageSize),
                pageSizeOptions: [50, 75, 100],
              }}
              loading={loading}
            />
          </div>
        </>
      )}
    </Box>
  );
};

export default AdminUser;
