/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { useParams } from "react-router";
import { GetReviewsByServiceId } from "../../services/Api/ServiceApi";
import "./Rating.scss"

const Ratings = () => {
	const { id } = useParams();
	const [reviewData, setReviewData] = useState([]);

	useLayoutEffect(() => {
		GetReviewsByServiceId(id)
			.then((res) => {
				setReviewData(res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	return (
		<Container className="reviews-container">
			<div className="views-all">
				<h3 className="page-title" style={{marginBottom:"20px"}}>REVIEWS MANAGEMENT</h3>
			</div>
			{reviewData?.length === 0 && (
				<Card className="no-reviews-card">
					<Card.Body>
						<p>No reviews available for this course.</p>
					</Card.Body>
				</Card>
			)}
			{reviewData?.map((review) => (
				<Card key={review.id} className="review-card">
					<Card.Body>
						<Row>
							<Col md={1} className="user-icon">
								<div className="user-circle">
									{review.service_review_user.user_profile.name.charAt(0)}
								</div>
							</Col>
							<Col md={11}>
								<div className="review-header">
									<div style={{display:"flex",flexDirection:"row"}}>
										<div>
											<div className="review-name">
												{review.service_review_user.user_profile.name}
											</div>
											<div className="review-rating">
												<FaStar />
												<div className="rating-number">{review.rating}</div>
											</div>
										</div>
										<div className="review-date">
											{new Date(review.created_at).toLocaleDateString()}
										</div>
									</div>
								</div>
								<Card.Text className="review-comment">
									{review.review}
								</Card.Text>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			))}
		</Container>
	);
};

export default Ratings;
