/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { GetUserById } from "../../services/Api/Api";
import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Space, Table, Tag } from "antd";
import { Row, Col } from "react-bootstrap";
import { GetBookingByUserId } from "../../services/Api/BookingApi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import "./Customers.css";

const ViewCustomer = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState([]);
	const [bookingData, setBookingData] = useState([]);
	const [activeTab, setActiveTab] = useState("all");

	useLayoutEffect(() => {
		GetUserById(id)
			.then((res) => {
				setUserData(res.data.data);
				console.log("user", res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	const getData = async (booking_status) => {
		try {
			const formData = { id: id, booking_status };
			let result = await GetBookingByUserId(formData);
			// Add an auto-increment ID to each booking
			const dataWithIndex = result.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setBookingData(dataWithIndex);
			console.log("userbooking==>", dataWithIndex);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getData("all");
	}, [id]);

	const navigateToUser = () => {
		navigate("/users");
	};

	const tabsContent = [
		{ label: "All", key: "all" },
		{ label: "Ongoing", key: "ONGOING" },
		{ label: "Upcoming", key: "UPCOMING" },
		{ label: "Completed", key: "COMPLETED" },
	];

	const formatDate = (dateString) => {
		if (!dateString) return "";
		return dateString.split("T")[0];
	};

	const getStatusColor = (status) => {
		switch (status) {
			case "DELETED":
			case "REJECTED":
				return "red";
			case "SUCCESS":
				return "green";
			case "ACCEPTED":
				return "purple";
			case "PENDING":
				return "yellow";
			case "COMPLETED":
				return "green"; // Assuming you want COMPLETED to have the same color as SUCCESS
			default:
				return "geekblue"; // Default color
		}
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Booking ID",
			dataIndex: "booking_unique_id",
			key: "booking_id",
		},
		{
			title: "Service",
			dataIndex: ["bookings", "service_booking", "name"],
			key: "service",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (date) => formatDate(date),
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
		},
		{
			title: "Address",
			dataIndex: ["bookings", "booking_address", "full_address"],
			key: "address",
		},
		{
			title: "Employees",
			dataIndex: "booking_service_provider_details",
			key: "service_providers",
			render: (serviceProviders) => {
				const serviceProviderNames = serviceProviders
					.filter((provider) => provider.service_provider_profile)
					.map(
						(provider) => provider.service_provider_profile.user_profile.name
					)
					.join(", ");
				return serviceProviderNames ? (
					serviceProviderNames
				) : (
					<span style={{ color: "red", fontWeight: 300 }}>
						No Employee assigned
					</span>
				);
			},
		},
		{
			title: "Team Leader",
			dataIndex: "booking_service_provider_details",
			key: "team_leader",
			render: (serviceProviders) => {
				const teamLeader = serviceProviders.find(
					(provider) => provider.is_team_leader
				);
				return teamLeader ? (
					teamLeader.service_provider_profile.user_profile.name
				) : (
					<span style={{ color: "red", fontWeight: 300 }}>
						No team leader assigned
					</span>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "booking_status",
			key: "status",
			render: (status) => {
				const color = getStatusColor(status);
				return (
					<Tag color={color}>
						{status === "SUCCESS" ? "COMPLETED" : status.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Service Start Time",
			dataIndex: "start_time",
			key: "start_time",
			render: (time) => (time ? new Date(time).toLocaleString() : "---"),
		},
		{
			title: "Service End Time",
			dataIndex: "end_time",
			key: "end_time",
			render: (time) => (time ? new Date(time).toLocaleString() : "---"),
		},
		{
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{ fontSize: "20px" }}
					className="redirect_button"
					onClick={() => navigate(`/viewBooking/${record.id}`)}
				/>
			),
		},
	];

	const handleTabSelect = (k) => {
		setActiveTab(k);
		let status = k === "all" ? "all" : k;
		getData(status);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="30px"
			>
				<div>
					<h3 className="page-title">Client MANAGEMENT</h3>
					<p className="page-sub-title">View Information related with Client</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToUser}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Clients</span>
					</Button>
				</div>
			</Box>

			<Card>
				<div className="admin_profile">
					<Form className="admin_details_form">
						<div>
							<Row>
								<Col>
									<div className="mb-3">
										<strong>Client's Name:</strong>
										<p>{userData?.user_profile?.name || "N/A"}</p>
									</div>
								</Col>
								<Col>
									<div className="mb-3">
										<strong>Client's Email:</strong>
										<p>{userData?.email || "N/A"}</p>
									</div>
								</Col>
								<Col>
									<div className="mb-3">
										<strong>Client's Mobile:</strong>
										<p>{userData?.user_profile?.mobile || "N/A"}</p>
									</div>
								</Col>
							</Row>

							<Row>
								<div className="mb-3">
									<strong>Client's Addresses:</strong>
									<ul>
										{userData?.user_address?.length > 0 ? (
											userData.user_address.map((address, index) => (
												<li key={index}>{address.full_address}</li>
											))
										) : (
											<p>No address available</p>
										)}
									</ul>
								</div>
							</Row>
						</div>
						<div>
							<h5 style={{ marginBottom: "20px", marginTop: "40px" }}>
								View all the bookings associated with{" "}
								{userData?.user_profile?.name}
							</h5>
							<Tabs activeKey={activeTab} onSelect={handleTabSelect}>
								{tabsContent.map((tab) => (
									<Tab eventKey={tab.key} title={tab.label} key={tab.key}>
										<div style={{ marginTop: "20px" }}>
											<Table
												columns={columns}
												dataSource={bookingData}
												rowKey="autoIncrementId"
											/>
										</div>
									</Tab>
								))}
							</Tabs>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default ViewCustomer;
