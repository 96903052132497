import axios from "axios";
import { BASE_URL } from "../Host";

export const GetAllBookingCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getBookingCount");
	return res;
};

export const GetPendingCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getPendingBookingCount");
	return res;
};

export const GetCompletedCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getCompletedBookingCount");
	return res;
};

export const GetDeletedCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getDeletedBookingCount");
	return res;
};

export const GetTotalRevenueGenerated = async (period) => {
	return await axios.post(
		`${BASE_URL}admin/user/totalRevenueGenerated`,
		{ period },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetUserCount = async () => {
	const res = await axios.get(BASE_URL + "/admin/user/getUserCount");
	return res;
};

export const GetDriverCount = async () => {
	const res = await axios.get(BASE_URL + "/admin/user/getDriverCount");
	return res;
};

export const GetMostBookedService = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getMostBookedService");
	return res;
};

export const GetServiceCount = async () => {
	const res = await axios.get(BASE_URL + "admin/user/getServiceCount");
	return res;
};

export const GetTopRatedCourses = async () => {
	return await axios.get(
		`${BASE_URL}admin/user/getTopRatedServices`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};
