/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Card } from "primereact/card";
import "./Bookings.css";
import { Button } from "primereact/button";
import { Row, Col } from "react-bootstrap";
import { GetDriverByAdmin } from "../../services/Api/Api";
import { GetBookingById } from "../../services/Api/BookingApi";
import { Form, Table, Tag } from "antd";

const ViewBooking = () => {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [driverList, setDriverList] = useState([]);
	const [bookingData, setBookingData] = useState({
		booking_status: "",
		booking_id: "",
		service_provider_ids: [],
		team_leader_id: "",
	});

	// Get drivers name
	const getDriversList = async () => {
		try {
			const res = await GetDriverByAdmin();
			if (res?.status === 200) {
				setDriverList(res.data.data);
			}
		} catch (error) {
			console.error("Failed to fetch driver list:", error);
		}
	};

	useEffect(() => {
		getDriversList();
	}, []);

	// Get booking by id
	useLayoutEffect(() => {
		const fetchBookingData = async () => {
			try {
				const res = await GetBookingById(id);
				setBookingData(res.data.data);
			} catch (error) {
				console.error("Failed to fetch booking data:", error);
			}
		};

		fetchBookingData();
	}, [id]);

	const onFinish = (values) => {
		console.log("Success:", values);
	};

	const navigateToBooking = () => {
		navigate("/bookings");
	};

	// Extract service providers and team leader names
	const serviceProviders = bookingData?.booking_service_provider_details
		?.filter((provider) => provider.service_provider_profile)
		.map(
			(provider) => provider.service_provider_profile?.user_profile?.name
		)
		.join(", ");
	const teamLeader = bookingData?.booking_service_provider_details?.find(
		(provider) => provider.is_team_leader
	);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">BOOKING MANAGEMENT</h3>
					<p style={{ color: "green" }} className="page-sub-title">
						View Booking details
					</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToBooking}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Bookings</span>
					</Button>
				</div>
			</Box>

			<Form form={form} onFinish={onFinish}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Card style={{ width: "100%", marginRight: "20px" }}>
						<div
							style={{
								margin: "0 0 7px 0px",
							}}
						>
							<h4
								style={{
									fontSize: "1.2rem",
									fontFamily: "Cerebri Sans,sans-serif",
									fontWeight: "600",
									marginBottom: "20px",
									color: "#1677FF",
								}}
							>
								{bookingData?.bookings?.type} Booking General Information :
							</h4>
							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Booking Id:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.booking_unique_id}
									</p>
								</Col>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Booking Status:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.booking_status}
									</p>
								</Col>
							</Row>

							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										User Name:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.booking_user?.user_profile?.name}
									</p>
								</Col>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Service Name:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.service_booking?.name}
									</p>
								</Col>
							</Row>

							{bookingData.booking_status === "CANCELLED" ? (
								<>
									<Row>
										<Col>
											<h5
												style={{
													fontSize: "0.9rem",
													fontFamily: "Cerebri Sans,sans-serif",
													fontWeight: "700",
													marginTop: "14px",
													color: "darkgray",
												}}
											>
												Cancelled by:
											</h5>
											<p style={{ marginBottom: "20px" }}>
												{bookingData?.cancellation_reason_by_user != null
													? "USER"
													: "EMPLOYEE"}
											</p>
										</Col>
										<Col>
											<h5
												style={{
													fontSize: "0.9rem",
													fontFamily: "Cerebri Sans,sans-serif",
													fontWeight: "700",
													marginTop: "14px",
													color: "darkgray",
												}}
											>
												Cancellation Reason:
											</h5>
											<p style={{ marginBottom: "20px" }}>
												{bookingData?.cancellation_reason_by_user != null
													? bookingData?.cancellation_reason_by_user
													: bookingData?.cancellation_reason_by_employee}
											</p>
										</Col>
									</Row>
								</>
							) : (
								<></>
							)}

							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Employees Assigned:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{serviceProviders
											? serviceProviders
											: "No Employee assigned"}
									</p>
								</Col>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Team Leader Assigned:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{teamLeader
											? teamLeader.service_provider_profile.user_profile
													.name
											: "No team leader assigned"}
									</p>
								</Col>
							</Row>

							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Service Start Time:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.start_time
											? new Date(bookingData.start_time).toLocaleString()
											: "---"}
									</p>
								</Col>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Service End Time:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.end_time
											? new Date(bookingData.end_time).toLocaleString()
											: "---"}
									</p>
								</Col>
							</Row>

							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Date:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData.date && bookingData?.date.split("T")[0]}
									</p>
								</Col>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Time:
									</h5>
									<p style={{ marginBottom: "20px" }}>{bookingData?.time}</p>
								</Col>
							</Row>

							<Row>
								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Addreess:
									</h5>
									<p style={{ marginBottom: "20px" }}>
										{bookingData?.booking_address?.full_address}
									</p>
								</Col>

								<Col>
									<h5
										style={{
											fontSize: "0.9rem",
											fontFamily: "Cerebri Sans,sans-serif",
											fontWeight: "700",
											marginTop: "14px",
											color: "darkgray",
										}}
									>
										Notes:
									</h5>
									<p style={{ marginBottom: "20px" }}>{bookingData?.notes}</p>
								</Col>
							</Row>
						</div>
					</Card>
				</div>
			</Form>
		</Box>
	);
};

export default ViewBooking;
